<template>
  <Breadcrumb />
  <div
    class="accordion mb-3 mt-2"
    :id="String.format('Accordion_{0}', gridSettings.action)"
    ref="accordion"
  >
    <div class="accordion-item">
      <h2
        class="accordion-header"
        :id="String.format('Accordion_{0}_head', gridSettings.action)"
      >
        <button
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="
            String.format('#Accordion_{0}_tab1', gridSettings.action)
          "
          aria-expanded="true"
          :aria-controls="
            String.format('Accordion_{0}_tab1', gridSettings.action)
          "
        >
          <p class="text-capitalize fs-5 mb-0">
            {{
              $t(
                "PermissionGroups.PageFilter",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </p>
        </button>
      </h2>
      <div
        :id="String.format('Accordion_{0}_tab1', gridSettings.action)"
        class="accordion-collapse collapse show"
        :aria-labelledby="
          String.format('Accordion_{0}_head', gridSettings.action)
        "
        :data-bs-parent="String.format('Accordion_{0}', gridSettings.action)"
      >
        <div class="accordion-body">
          <div class="row align-items-center">
            <div class="col-md-2">
              {{
                $t(
                  "PermissionGroups.ParentPermissionGroups",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
            <div class="col-md-10">
              <FormSelect
                :resetSelect="selectsReset"
                type="SelectWithRemoteDataAndInternalSearch"
                :isDetailIconShow="true"
                requestUrl="/Lcdp-SummaryOrganizationalUnitItems?name=PermissionGroup&filterType=3"
                @onChange="onChangePermissionGroup"
                :isParameters="true"
                :isGatewayRequest="true"
              />
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-md-2">
              {{
                $t(
                  "PermissionGroups.ActivePassive",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
            <div class="col-md-10">
              <FormSelect
                id="ActivePassive"
                type="SelectWithLocalData"
                :resetSelect="selectsReset"
                :selectedData="selectedActivePassive"
                :allowEmpty="false"
                :data="activePassiveData"
                @onChange="onChangeActivePassive"
              />
            </div>
          </div>
          <div class="col-md-12 mt-2 text-end">
            <button
              type="button"
              class="btn btn-success btn-history-filter-search me-2"
              @click="onSearch('btn-history-filter-search')"
            >
              <span>
                {{
                  $t(
                    "PermissionGroups.Search",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </span>
            </button>
            <button
              type="button"
              class="btn btn-history-filter-clear btn-danger"
              @click="onClear"
            >
              {{
                $t(
                  "PermissionGroups.Clear",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end">
    <router-link to="New" class="btn btn-success btn-action-new-page"
      ><i class="bi bi-plus"></i>
      {{
        $t(
          "PermissionGroups.NewButton",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}</router-link
    >
  </div>
  <Grid
    :settings="gridSettings"
    :columns="gridColumns"
    @onRequestFinally="onSearchEnd"
  />
</template>
<script>
import $ from "jquery";
export default {
  name: "PermissionGroupList",
  components: {},
  data() {
    return {
      selectsReset: false,
      activePassiveData: [
        {
          key: 1,
          value: this.$t(
            "BaseModelFields.Active",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BaseModelFields.Passive",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedActivePassive: [],
      gridSettings: {
        action: "PermissionGroupList",
        requestUrl: String.format("/Lcdp-ListPermissionGroups"),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [
          {
            key: "permissionGroupId",
            value: "publicId",
            isRouteParameter: false,
          },
        ],

        isGatewayRequest: true,

        serialNoLink: `#/PermissionGroup/Edit/`,

        allowSearchPanel: true,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: true,
        deleteActionUrl: "/Lcdp-DeletePermissionGroup",
        autoOrderProcess: true,

        buttons: [
          {
            name: "detail",
            cssClass: "btn-primary",
            iconClass: "bi-people-fill",
            routeButton: true,
            routeObj: {
              name: "PermissionGroupDetail",
              params: {
                permissionGroupId: "",
              },
            },
            iconAttributes: {
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "top",
              title: this.$t(
                "PermissionGroups.DetailTitle",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          },
          {
            name: "permission",
            cssClass: "btn-info",
            iconClass: "bi-lock",
            routeButton: true,
            routeObj: {
              name: "PermissionGroupGroups",
              params: {
                permissionGroupId: "",
              },
            },
            iconAttributes: {
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "top",
              title: this.$t(
                "PermissionGroups.PermissionSettings",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          },
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "PermissionGroupEdit",
              params: {
                permissionGroupId: "",
              },
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "PermissionGroups.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "PermissionGroups.Description",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "description",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "PermissionGroups.ParentPermissionGroup",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "parentPermissionGroupName",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
      ],
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses:
        ".btn-history-filter-search, .btn-history-filter-clear",
    };
  },
  methods: {
    onChangePermissionGroup(selected) {
      this.permissionGroupPublicId = selected.key;
    },
    onChangeActivePassive(selected) {
      if (selected.key == 1) {
        this.isActive = "true";
      } else if (selected.key == 2) {
        this.isActive = "false";
      } else {
        this.isActive = "";
      }
    },
    onSearch(buttonName) {
      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      var searchUrl = "Lcdp-ListPermissionGroups",
        requestParameters = [];
      if (!String.isNullOrWhiteSpace(this.permissionGroupPublicId)) {
        requestParameters.push({
          key: "parentPublicId",
          value: this.permissionGroupPublicId,
        });
      }

      if (!String.isNullOrWhiteSpace(this.isActive)) {
        requestParameters.push({
          key: "isActive",
          value: this.isActive,
        });
      }

      this.gridSettings.requestUrl = ""; //trigger watch parameter
      this.gridSettings.requestUrl = String.createGetUrl(
        searchUrl,
        requestParameters
      );
    },
    onSearchEnd() {
      var button = $(String.format(".{0}", "btn-history-filter-search")),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);
      firstSpan.show();
      loadingBlock.hide();
      disabledButtons.prop("disabled", false);
    },
    onClear() {
      this.selectsReset = !this.selectsReset;
    },
  },
  mounted() {},
};
</script>
